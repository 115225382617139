import {IConfig} from "@/config/types";

const config: IConfig = {
  project: "admin",
  httpPrefix: "/api",
  timeOut: 10 * 60 * 1000,
  platformName: "高尔夫管理系统",
  company: "深圳市榆晟科技有限责任公司",
  uploadUrl: "/party/oss/ordinaryUpload",
  fragmentationUrl: "/party/oss/burstUpload",
  downloadUrl: "/api/party/oss/download",
  videoDownloadUrl:'/party/oss/play/video'
}

export default config
